import { useMemo } from "react";
import {
    makeStyles,
    Box,
    Container,
    Typography,
    Divider,
    Grid,
    Button,
    CircularProgress,
} from "@material-ui/core";
import { useTracking } from "react-tracking";
import PageBase from "../components/PageBase";
import Epic from "./Epic";
import useKnowledgeBank from "../hooks/queries/useKnowledgeBank";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    panel: {
        border: '1px solid #bfbfbf',
        borderRadius: '5px',
        padding: '10px',
        height: '100%'
    },
    title: {
        background: theme.palette.title.background,
        color: theme.palette.title.text,
        padding: '2px 8px',
        borderRadius: '5px',
        width: 'inherit'
    },
    tileContent: {
        textOverflow: 'ellipsis',
        xheight: '30px',
        xmaxHeight: '25px',
        overflow: 'hidden',
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
    }

}));

const Events = () => {
    const classes = useStyles();
    const { Track } = useTracking({
        page: 'KnowledgeBank-Events'
    });

    const { data, isLoading } = useKnowledgeBank();    

    const events = useMemo(() => data?.events || []);

    if (isLoading)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <CircularProgress />
            </Box>
        );

    return (
        <PageBase>
            <Track>
                <Container>
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" gridGap="15px">
                        <Epic
                            title="Events"
                            main="Lorem ipsum ..."
                        />
                        <Grid container spacing={2}>

                            {events.map(e =>
                                <Grid item xs={12} md={4} key={e.id}>
                                    <Box className={classes.panel} display="flex" gridGap="5px" flexDirection="column">
                                        <Box><Typography>{format(new Date(e.start), "d MMM yyyy")}</Typography></Box>       
                                        <Box flex={1}><Typography>{e.name}</Typography></Box>
                                        <Divider style={{ margin: '10px -10px' }} />
                                        <Box display="flex" justifyContent="flex-end" gridGap="5px">
                                            <Button component={Link} to={`/knowledge/event/${e.id}`}>More Info</Button>
                                            {e.registerUrl && <Button href={e.registerUrl} target="_blank" variant="outlined" color="secondary">Register</Button>} 
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Container>
            </Track>
        </PageBase>
    );
}

export default Events;
